@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

:root {
  --bg: #242526;
  --bg-accent: #484a4d;
  /* --text-color: #dadce1; */
  --nav-size: 60px;
  --border: 1px solid #00000040;
  --border-radius: 8px;
  --speed: 500ms;
}

ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

a {
  color: var(--text-color);
  text-decoration: none;
}

body {
  font-family: "Poppins", sans-serif;
}

li,
a,
button {
  font-size: 18px;
  text-decoration: none;
}

.link {
  text-decoration: none;
  cursor: pointer !important;
  position: relative;
  color: #fff;
}
.btn--m {
  border-radius: 7px;
  font-size: 15px;
  padding: 0 21px;
  height: 50px;
  line-height: 45px;
  background: rgb(60, 179, 113);
  margin-top: 25px;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.btn {
  cursor: pointer;
  width: 100%;

  font-weight: 600;
  font-family: inherit;
  text-align: center;
  white-space: nowrap;
  border: none;
  text-decoration: none;
}

.btn:focus {
  outline: none;
}

.btn:active {
  transform: scale(0.98);
}

.btn-block {
  display: block;
  width: 100%;
}

.add-form {
  margin-bottom: 40px;
}
.form-container {
  max-width: 500px;
  margin: 30px auto;
  overflow: auto;
  min-height: 350px;
  max-height: 500px;
  border: 1.7px solid rgba(52, 54, 56, 0.404);
  border-radius: 15px;
  flex-basis: 0;
  padding: 35px;
}

.panel_body {
  flex-basis: 100%;
  flex-grow: 1;
  padding: 24px;
  border: 1.7px solid rgba(52, 54, 56, 0.404);
  border-radius: 10px;
}
.container {
  margin: 30px auto;
  overflow: auto;
  max-width: 625px;
  display: flex;
  flex-direction: column;
  padding: 35px;
}

.form-control {
  margin: 16px 0;
}

.form-feild {
  padding-bottom: 15px;
}

.form-control div label {
  display: block;
  padding-bottom: 2px;
  color: #4b4a4f;
  text-transform: uppercase;
  font-size: 16.2px;
  letter-spacing: 0.04em;
}

.form-control input {
  width: 100%;

  border: 1.7px solid rgba(52, 54, 56, 0.404);
  border-radius: 7px;
  height: 46px;
  padding: 0 14px;
  font-size: 17px;
}

.form-control-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-control-check label {
  flex: 1;
}

.form-control-check input {
  flex: 2;
  height: 20px;
}

footer {
  margin-top: 30px;
  text-align: center;
}
