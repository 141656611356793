.dasboard-master-container {
  background: #f2f9f0;
}

.farmlist-container {
  display: flex;
  flex-direction: column;
  /* background: #f2f9f0; */
  max-width: 1410px;
  margin: 0px auto;
}
.farm-heading {
  margin: 12px;
  padding: 15px 67px;
  font-weight: 500;
  font-size: 25px;
  text-align: center;
}

.farm-heading span {
  margin: 10px auto;
}
.line-chart {
  border-radius: 5px;
  margin: 20px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
  text-align: left;
}
.farm-lable {
  margin: 10px 10px;
}

.farm-container {
  margin: 30px;
}

.farm-container-heading {
  margin: 0px 60px;
  display: flex;
}

.farm-day-selecter {
  background: #fff;
  border-radius: 5px;
  max-width: fit-content;
  margin-left: auto;
  box-shadow: rgb(0 0 0 / 10%) -4px 9px 25px -6px;
  justify-self: flex-end;
  transition: all 0.5s linear;
}

.farm-day-selecter-isactive {
  /* box-shadow: rgb(21, 175, 91) 0 1px 0 0, inset rgb(21, 175, 91) 0 -1px 0 0; */
  color: rgb(21, 175, 91) !important;
}

.farm-day-selecter a {
  cursor: pointer;
  font-size: medium;
}

.farm-day-selecter ul {
  display: flex;
  align-items: center;
}

.farm-day-selecter li {
  margin: 0px 10px;
}

.farm-day-selecter-spacer {
  margin: 0px 0px !important;
  height: 13px;
  width: 2px;
  background: #a0a0a0;
}

.farm-container-heading span {
  font-size: large;
  font-weight: 500;
}
.chart-container {
  text-align: center;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
}

.chart-container span {
  font-size: large;
  font-weight: 600;
  margin: 20px;
}
