h1 {
  position: relative;
}
h3 {
  font-weight: 200;
}

.page-title {
  max-width: 400px;
  margin: 30px auto;
  padding: 15px;
  text-align: center;
}

.form-container {
  max-width: 500px;
  margin: 30px auto;
  overflow: auto;
  min-height: 550px;
  border: 1.7px solid rgba(52, 54, 56, 0.404);
  border-radius: 15px;
  flex-basis: 0;
  padding: 35px;
}
