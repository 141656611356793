.sub-header {
  background: #fff;
  border-bottom: 1px solid #00000040;
  width: 100%;
  padding-left: 14px;
  padding-right: 14px;
  position: relative;
  text-align: center;
  z-index: 2;
}
.nav--horizontal {
  text-align: center;
}
.nav--horizontal ul {
  display: inline-flex;
  height: 100%;
  font-size: large;
}
.nav {
  display: inline-block;
}
.farm-tab-list {
  margin: auto;
  list-style-type: none;
}
.farm-tab-links {
  padding: 10px;
  cursor: pointer;
  transition: all 0.5s linear;
}
.farm-tab-links-isactive {
  box-shadow: rgb(21, 175, 91) 0 1px 0 0, inset rgb(21, 175, 91) 0 -1px 0 0;
  color: rgb(21, 175, 91) !important;
}
