.weather-master-container {
  background: #f2f9f0;
}

.city-name{
  align-items: center;
  text-align: center;
  margin: 0px 0px;
}
.weather-data-container {
  margin: 5px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  transition: all 0.2s ease-in-out; 
}