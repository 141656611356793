@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap);
h1 {
  position: relative;
}
h3 {
  font-weight: 200;
}

.page-title {
  max-width: 400px;
  margin: 30px auto;
  padding: 15px;
  text-align: center;
}

.form-container {
  max-width: 500px;
  margin: 30px auto;
  overflow: auto;
  min-height: 550px;
  border: 1.7px solid rgba(52, 54, 56, 0.404);
  border-radius: 15px;
  flex-basis: 0;
  padding: 35px;
}

.current-data-heading {
  margin: 10px 90px;
}

.current-data-heading span {
  font-size: large;
  font-weight: 500;
}

.current-data-container {
  margin: 5px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  transition: all 0.2s ease-in-out;
}

.current-data-container :hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.current-data-card {
  max-height: 130px;
  width: 274px;
  margin: 5px 24px;
  padding: 0 4px;
  background: #fff;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.current-data-card :hover {
  -webkit-transform: scale(1);
          transform: scale(1);
}

@media (max-width: 1430px) {
  .current-data-card {
    margin: 20px 50px 0px 100px;
  }
}

.current-data-card-lable {
  font-weight: 700;
}

.sub-header {
  background: #fff;
  border-bottom: 1px solid #00000040;
  width: 100%;
  padding-left: 14px;
  padding-right: 14px;
  position: relative;
  text-align: center;
  z-index: 2;
}
.nav--horizontal {
  text-align: center;
}
.nav--horizontal ul {
  display: inline-flex;
  height: 100%;
  font-size: large;
}
.nav {
  display: inline-block;
}
.farm-tab-list {
  margin: auto;
  list-style-type: none;
}
.farm-tab-links {
  padding: 10px;
  cursor: pointer;
  transition: all 0.5s linear;
}
.farm-tab-links-isactive {
  box-shadow: rgb(21, 175, 91) 0 1px 0 0, inset rgb(21, 175, 91) 0 -1px 0 0;
  color: rgb(21, 175, 91) !important;
}

.dasboard-master-container {
  background: #f2f9f0;
}

.farmlist-container {
  display: flex;
  flex-direction: column;
  /* background: #f2f9f0; */
  max-width: 1410px;
  margin: 0px auto;
}
.farm-heading {
  margin: 12px;
  padding: 15px 67px;
  font-weight: 500;
  font-size: 25px;
  text-align: center;
}

.farm-heading span {
  margin: 10px auto;
}
.line-chart {
  border-radius: 5px;
  margin: 20px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
  text-align: left;
}
.farm-lable {
  margin: 10px 10px;
}

.farm-container {
  margin: 30px;
}

.farm-container-heading {
  margin: 0px 60px;
  display: flex;
}

.farm-day-selecter {
  background: #fff;
  border-radius: 5px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin-left: auto;
  box-shadow: rgb(0 0 0 / 10%) -4px 9px 25px -6px;
  justify-self: flex-end;
  transition: all 0.5s linear;
}

.farm-day-selecter-isactive {
  /* box-shadow: rgb(21, 175, 91) 0 1px 0 0, inset rgb(21, 175, 91) 0 -1px 0 0; */
  color: rgb(21, 175, 91) !important;
}

.farm-day-selecter a {
  cursor: pointer;
  font-size: medium;
}

.farm-day-selecter ul {
  display: flex;
  align-items: center;
}

.farm-day-selecter li {
  margin: 0px 10px;
}

.farm-day-selecter-spacer {
  margin: 0px 0px !important;
  height: 13px;
  width: 2px;
  background: #a0a0a0;
}

.farm-container-heading span {
  font-size: large;
  font-weight: 500;
}
.chart-container {
  text-align: center;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
}

.chart-container span {
  font-size: large;
  font-weight: 600;
  margin: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #00000040;
  height: 65px;
  padding: 0px 60px;
}

header nav {
  display: flex;
  align-items: center;
}

.logo {
  cursor: pointer;
  width: 50px;
}

.nav__links {
  list-style: none;
  font-weight: 500;
}

.nav__links li {
  display: inline-block;
  padding: 0px 20px;
}

.nav__links li a {
  transition: all 0.3 ease 0s;
}

.nav__links li a:hover {
  color: rgb(21, 175, 91);
}
/* 
.cta button {
  padding: 5px 20px;
  background-color: rgb(35, 223, 120);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3 ease 0s;
  margin-right: 5px;
}
.cta button:hover {
  background-color: rgb(30, 184, 99);
} */

.avatar-button-container {
  margin-right: 40px;
}

.avatar-button-container button {
  background-color: yellowgreen;
  border-radius: 5px;
  width: 60px;
}

.avatar-button-container a {
  transition: all 0.3 ease 0s;
}

.avatar-button-container a:hover {
  color: #0088a9;
}

.drop-down-active {
  display: inline-block;
  position: absolute;
  background-color: white;
  top: 50px;
  right: 38px;
  z-index: 5;
  border: 1px solid black;
}

.drop-down-active ul {
  list-style: none;
}

.weather-master-container {
  background: #f2f9f0;
}

.city-name{
  align-items: center;
  text-align: center;
  margin: 0px 0px;
}
.weather-data-container {
  margin: 5px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  transition: all 0.2s ease-in-out; 
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

:root {
  --bg: #242526;
  --bg-accent: #484a4d;
  /* --text-color: #dadce1; */
  --nav-size: 60px;
  --border: 1px solid #00000040;
  --border-radius: 8px;
  --speed: 500ms;
}

ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

a {
  color: var(--text-color);
  text-decoration: none;
}

body {
  font-family: "Poppins", sans-serif;
}

li,
a,
button {
  font-size: 18px;
  text-decoration: none;
}

.link {
  text-decoration: none;
  cursor: pointer !important;
  position: relative;
  color: #fff;
}
.btn--m {
  border-radius: 7px;
  font-size: 15px;
  padding: 0 21px;
  height: 50px;
  line-height: 45px;
  background: rgb(60, 179, 113);
  margin-top: 25px;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.btn {
  cursor: pointer;
  width: 100%;

  font-weight: 600;
  font-family: inherit;
  text-align: center;
  white-space: nowrap;
  border: none;
  text-decoration: none;
}

.btn:focus {
  outline: none;
}

.btn:active {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
}

.btn-block {
  display: block;
  width: 100%;
}

.add-form {
  margin-bottom: 40px;
}
.form-container {
  max-width: 500px;
  margin: 30px auto;
  overflow: auto;
  min-height: 350px;
  max-height: 500px;
  border: 1.7px solid rgba(52, 54, 56, 0.404);
  border-radius: 15px;
  flex-basis: 0;
  padding: 35px;
}

.panel_body {
  flex-basis: 100%;
  flex-grow: 1;
  padding: 24px;
  border: 1.7px solid rgba(52, 54, 56, 0.404);
  border-radius: 10px;
}
.container {
  margin: 30px auto;
  overflow: auto;
  max-width: 625px;
  display: flex;
  flex-direction: column;
  padding: 35px;
}

.form-control {
  margin: 16px 0;
}

.form-feild {
  padding-bottom: 15px;
}

.form-control div label {
  display: block;
  padding-bottom: 2px;
  color: #4b4a4f;
  text-transform: uppercase;
  font-size: 16.2px;
  letter-spacing: 0.04em;
}

.form-control input {
  width: 100%;

  border: 1.7px solid rgba(52, 54, 56, 0.404);
  border-radius: 7px;
  height: 46px;
  padding: 0 14px;
  font-size: 17px;
}

.form-control-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-control-check label {
  flex: 1 1;
}

.form-control-check input {
  flex: 2 1;
  height: 20px;
}

footer {
  margin-top: 30px;
  text-align: center;
}

